import "../styles/layouts/UIIconNavBar.scss";

export default function UIIconNavBar() {
  return (
    <div>
      <div class="icon nav-icon-5">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
}
